import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class VendorsProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('vendors', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`vendors/${id}`)
  }

  createItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('vendors', payload)
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.put(`vendors/${payload.id}`, payload)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`vendors/${id}`)
  }

  sync () {
    this.setHeader(getAuthToken())
    return this.post('vendors/migrate-bc')
  }
}

export default VendorsProvider
